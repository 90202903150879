<div class="header">
    <img class="wpp-logo" src="assets/wpp.svg">
</div>

<div class="content">
    <img class="wpp-img" src="assets/anota-logo.jpg">
    <div class="contact"><span> Anota AI </span> <img class="oba" src="assets/oba.webp"></div>
    <form [formGroup]="form">
        <input class="field" name="first_name" type="text" placeholder="Seu nome" formControlName="name">
        <input class="field" name="phone" type="text" mask="(00) 00000-0000" formControlName="phone"
            placeholder="Telefone">
        <input class="field" name="email" type="email" placeholder="Email" formControlName="email">
        <input class="field" name="company" type="text" placeholder="Nome do seu estabelecimento"
            formControlName="company">
        <div class="radbut-container" *ngIf="lead_type == 'garcom'; else lead_delivery">
            <div class="radbut">
                <span>Quantidade de Garçons</span>
                
                <div class="radbut2">
                    <label class="block"><input type="radio" name="waiter_quantity" value="Menos de 3"
                            formControlName="waiter_quantity">Menos de 3</label>
                    <label class="block"><input type="radio" name="waiter_quantity" value="De 3 a 5"
                            formControlName="waiter_quantity">De 3 a 5</label>
                    <label class="block"><input type="radio" name="waiter_quantity" value="Mais de 5"
                            formControlName="waiter_quantity">Mais de 5</label>
                </div>
            </div>
            <div class="radbut">
                <span>Quantidade de Mesas</span>
                <div class="radbut2">
                    <label class="block"><input type="radio" name="waiter_tables" value="Menos de 15"
                            formControlName="waiter_tables">Menos de 15</label>
                    <label class="block"><input type="radio" name="waiter_tables" value="De 15 a 30"
                            formControlName="waiter_tables">De
                        15 a 30</label>
                    <label class="block"><input type="radio" name="waiter_tables" value="Mais de 30"
                            formControlName="waiter_tables">Mais
                        de 30</label>
                </div>
            </div>
        </div>

        <ng-template #lead_delivery>
            <div class="radbut">
                <span>Quantidade de pedidos por dia</span>
                <div class="radbut2">
                    <label class="block"><input type="radio" name="order_day" value="Menos de 10"
                            formControlName="order_day">Menos de 10</label>
                    <label class="block"><input type="radio" name="order_day" value="Mais de 10"
                            formControlName="order_day">Mais de 10</label>
                </div>
            </div>
            <div class="radbut">
                <span>Você possui computador?</span>
                <div class="radbut2">
                    <label class="block"><input type="radio" name="computer" value="Sim"
                            formControlName="computer">Sim</label>
                    <label class="block"><input type="radio" name="computer" value="Não"
                            formControlName="computer">Não</label>
                </div>
            </div>
        </ng-template>

        <button type="submit" [ngStyle]="{'opacity': form.disabled ? '0.6' : '1'}" [disabled]="form.disabled"
            (click)="submit()"><span>Iniciar
                conversa</span></button>
    </form>
</div>
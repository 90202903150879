import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'

import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { MatIconModule } from '@angular/material/icon'
import { MatExpansionModule } from '@angular/material/expansion'

import { NgxMaskModule } from 'ngx-mask'

import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { DsModule } from '@githubanotaai/design-system'
import { HttpClientModule } from '@angular/common/http'
import { HotToastModule } from '@ngneat/hot-toast'
import { LoaderComponent } from './shared/components/loader/loader.component'
import { PulseLoaderComponent } from './shared/components/pulse-loader/pulse-loader.component'
import { InputComponent } from './shared/components/input/input.component'

import { environment } from 'src/environments/environment'
import { GoogleTagManagerModule } from 'angular-google-tag-manager'
import { RedirectGuard } from './shared/guards/redirect.guard'

import { ClipboardModule } from 'ngx-clipboard'
import { PhonePipe } from './shared/pipes/phone/phone.pipe'
import { MatTabsModule } from '@angular/material/tabs'
import { MatDialogModule } from '@angular/material/dialog'
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FormComponent } from './pages/form/form.component'

@NgModule({
	declarations: [
		AppComponent,
		LoaderComponent,
		PulseLoaderComponent,
		InputComponent,
		PhonePipe,
  FormComponent
	],
	imports: [
		MatTabsModule,
		BrowserModule,
		MatIconModule,
		MatExpansionModule,
		AppRoutingModule,
		DsModule,
		MatButtonToggleModule,
		NgxMaskModule.forRoot(),
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		HotToastModule.forRoot(),
		GoogleTagManagerModule.forRoot({
			id: environment.TAG_MANAGER_ID
		}),
		ClipboardModule,
		MatDialogModule,
		MatProgressBarModule
	],
	providers: [RedirectGuard],
	bootstrap: [AppComponent]
})
export class AppModule {}

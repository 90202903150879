import {
	Component,
	OnInit,
	AfterContentChecked,
	ChangeDetectorRef
} from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { GoogleTagManagerService } from 'angular-google-tag-manager'
import { environment } from 'src/environments/environment'

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterContentChecked {
	title = 'whatsapp-form-front'
	public step = 1
	public showManual = false
	public show = true
	constructor(
		private router: Router,
		private gtmService: GoogleTagManagerService,
		private cdr: ChangeDetectorRef
	) {}
	ngOnInit(): void {
		console.log('init')
		this.router.events.forEach((event) => {
			if (event instanceof NavigationEnd) {
				this.gtmService.pushTag({
					event: 'page',
					pageName: event.url
				})

				if (event.url.match(/next-step|email-sent/)) {
					this.show = false
				} else {
					this.show = true
				}
			}
		})
	}
	public track(eventType: string): void {
		if (!environment.production) return
		const gtmTag = {
			event: eventType,
		}
		this.gtmService.pushTag(gtmTag)
	}

	ngAfterContentChecked(): void {
		this.cdr.detectChanges()
	}
}

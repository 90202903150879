// // This file can be replaced during build by using the `fileReplacements` array.
// // `ng build` replaces `environment.ts` with `environment.prod.ts`.
// // The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	CRM_API_URL: 'https://staging-crm-api.anota-ai.com',
	TAG_MANAGER_ID: 'GTM-TH4RWV3T',
	INBOUND_URL: 'https://staging-inbound.anota-ai.com',
	REGISTER_FRONTEND_URL: 'https://staging-register-frontend-beta.anota-ai.com'
}

// /*
//  * For easier debugging in development mode, you can import the following file
//  * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
//  *
//  * This import should be commented out in production mode because it will have a negative impact
//  * on performance if an error is thrown.
//  */
// // import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

import { Injectable } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { CookieService } from 'ngx-cookie-service'
import { BehaviorSubject } from 'rxjs'

@Injectable({
	providedIn: 'root'
})
export class StorageService {
	public accountAlreadyUsed: boolean = false

	public isLP: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

	constructor(
		private cookieService: CookieService,
		private route: ActivatedRoute,
		private router: Router
	) {}

	getForm() {
		const getSessionSignUpForm = sessionStorage.getItem(
			'@anota-ai/initiate/register'
		)

		return JSON.parse(getSessionSignUpForm) ?? {}
	}

	updateForm(data: object) {
		const signUpForm = this.getForm()

		const result = Object.assign(signUpForm, data)

		sessionStorage.setItem(
			'@anota-ai/initiate/register',
			JSON.stringify(result)
		)
	}

	clearForm() {
		sessionStorage.removeItem('@anota-ai/initiate/register')
		sessionStorage.removeItem('@anota-ai/initiate/form')
	}
}

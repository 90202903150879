import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { FormComponent } from './pages/form/form.component'
import { LoaderComponent } from './shared/components/loader/loader.component'

const routes: Routes = [
	{
		path: '',
		component: FormComponent
	},
	{
		path: 'loader',
		component: LoaderComponent
	},
	{
		path: '**',
		redirectTo: 'error'
	}
]

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {}

import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
	name: 'phonePipe'
})
export class PhonePipe implements PipeTransform {
	transform(phoneNumber: string): string {
		if (!phoneNumber) {
			return ''
		}

		// Removing any non-numeric characters
		const cleaned = ('' + phoneNumber).replace(/\D/g, '')

		// Checking if the number is valid
		if (cleaned.length < 10 || cleaned.length > 11) {
			return phoneNumber
		}

		// Formatting
		const part1 = cleaned.slice(0, 2)
		const part2 = cleaned.slice(2, cleaned.length - 4)
		const part3 = cleaned.slice(cleaned.length - 4)

		return `(${part1}) ${part2}-${part3}`
	}
}

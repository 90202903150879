import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { HotToastService } from '@ngneat/hot-toast'
import { AppComponent } from 'src/app/app.component'
import { CrmApiService } from 'src/app/shared/services/crm-api/crm-api.service'
import { StorageService } from 'src/app/shared/services/storage/storage.service'
import { UtmsService } from 'src/app/shared/services/utms/utms.service'
import { environment } from 'src/environments/environment'

@Component({
	selector: 'app-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {
	public form: FormGroup
	public lead_type: string
	constructor(
		private storageService: StorageService,
		private utmsService: UtmsService,
		private route: ActivatedRoute,
		private crmApi: CrmApiService,
		private toast: HotToastService,
		private appComponent: AppComponent
	) {}

	ngOnInit(): void {
		this.utmsService.setUtmCookies()
		this.lead_type = this.utmsService.getUtms().lead_type
		this.initializeForm()
	}

	initializeForm() {
		const storageForm = this.storageService.getForm()

		this.form = new FormGroup({
			name: new FormControl(storageForm.name ?? '', [Validators.required]),
			email: new FormControl(storageForm.email ?? '', [
				Validators.required,
				Validators.email
			]),
			phone: new FormControl(storageForm.phone ?? '', [
				Validators.required,
				Validators.minLength(11),
				Validators.maxLength(11)
			]),
			company: new FormControl(storageForm.company ?? '', [
				Validators.required
			]),
			order_day: new FormControl(storageForm.order_day ?? '', [
				Validators.required
			]),
			computer: new FormControl(storageForm.computer ?? '', [
				Validators.required
			]),
			waiter_tables: new FormControl(storageForm.waiter_tables ?? '', [
				Validators.required
			]),
			waiter_quantity: new FormControl(storageForm.waiter_quantity ?? '', [
				Validators.required
			]),
			acceptTerms: new FormControl(false, [Validators.required])
		})

		if (this.lead_type == 'garcom') {
			this.form.get('computer').removeValidators(Validators.required)
			this.form.get('order_day').removeValidators(Validators.required)
			this.form.get('computer').setValue('Não')
			this.form.get('order_day').setValue('Menos de 10')
		} else {
			this.form.get('waiter_tables').removeValidators(Validators.required)
			this.form.get('waiter_quantity').removeValidators(Validators.required)
		}
	}

	async submit() {
		console.log('form', this.form.value)
		if (this.form.invalid) {
			this.toast.error('Preencha todos os campos corretamente.')
			return
		}
		this.form.disable()
		const utms = this.utmsService.getUtms()
		const data = {
			name: this.form.get('name').value,
			first_name: this.form.get('name').value,
			email: this.form.get('email').value,
			phone: this.form.get('phone').value,
			order_day: this.form.get('order_day').value,
			company: this.form.get('company').value,
			computer: this.form.get('computer').value,
			waiter_tables: this.form.get('waiter_tables').value,
			waiter_quantity: this.form.get('waiter_quantity').value,
			acceptTerms: this.form.get('acceptTerms').value,
			site_origem: 'Whatsapp Live Chat',
			ref: this.route.snapshot.queryParamMap.get('ref'),
			...utms
		}
		this.storageService.updateForm(data)

		Object.keys(data).forEach((key) =>
			data[key] == null || data[key] == 'undefined' ? delete data[key] : {}
		)

		try {
			let response: any = await this.crmApi.upsertDeal(data).toPromise()
			let responsible = response.data.responsible
			this.appComponent.track('CREATE-DEAL')
			if (!responsible || !responsible.phone) {
				if (this.lead_type == 'garcom') {
					this.toast.loading(
						`Aguarde o contato do vendedor no celular cadastrado. Estamos te redirecionando para o cadastro do nosso teste gratuito de 7 dias!`,
						{
							duration: 7000
						}
					)
				} else {
					if (data.computer == 'Não') {
						this.toast.loading(
							`Notamos que você não possui computador. Por isso, estamos te redirecionando para o cadastro do nosso teste gratuito de 7 dias!`,
							{
								duration: 7000
							}
						)
					} else if (data.order_day == 'Menos de 10') {
						this.toast.loading(
							`Notamos que você não possui mais de 10 pedidos diários. Por isso, estamos te redirecionando para o cadastro do nosso teste gratuito de 7 dias!`,
							{
								duration: 7000
							}
						)
					} else {
						this.toast.loading(
							`Aguarde o contato do vendedor no celular cadastrado. Estamos te redirecionando para o cadastro do nosso teste gratuito de 7 dias!`,
							{
								duration: 7000
							}
						)
						await this.crmApi.reportError(responsible, response.data?.dealId).toPromise()
					}
				}

				setTimeout(() => {
					window.location.href = `${environment.REGISTER_FRONTEND_URL}/credentials?id_deal=${response.data.deal._id}`
				}, 7000)
				return
			}
			let responsible_phone = responsible.phone.replace(/\D/g, '')
			if (responsible_phone.startsWith('99')) {
				this.toast.loading(
					`Seu consultor não está disponível no momento. Ele irá entrar em contato em instantes. Enquanto isso, estamos te redirecionando para o cadastro do nosso teste gratuito de 7 dias!`,
					{
						duration: 7000
					}
				)
				await this.crmApi.reportError(responsible, response.data?.dealId).toPromise()
				setTimeout(() => {
					window.location.href = `${environment.REGISTER_FRONTEND_URL}/credentials?id_deal=${response.data.deal._id}`
				}, 7000)
				return
			}

			let whatsappUrl = `https://api.whatsapp.com/send?phone=55${responsible_phone}`

			if(responsible.name == "SOFIA"){
				setTimeout(() => {
					window.location.href = whatsappUrl
				}, 7000)
				return
			} else{ 
				window.location.href = `${whatsappUrl}&text=Ol%C3%A1%2C%20gostaria%20de%20obter%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20Anota%20AI.%20Meu%20protocolo%20%C3%A9%3A%20${data.phone}.`
			}

			this.form.enable()
		} catch (error) {
			console.error('Error on submit', error)
			this.toast.error('Erro ao enviar formulário. Tente novamente.')
			this.form.enable()
		}
	}
}

import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from 'src/environments/environment'

@Injectable({
	providedIn: 'root'
})
export class CrmApiService {
	private baseUrl = environment.CRM_API_URL
	private proxyUrl = environment.INBOUND_URL
	constructor(private http: HttpClient) {}

	public upsertDeal(deal) {
		return this.http.post(`${this.proxyUrl}/deals?isTrial=true`, deal)
	}

	getDeal(dealId: string): any {
		return this.http.get(`${this.baseUrl}/deals/${dealId}`)
	}

	updateDealStep(dealId: any, step: string) {
		return this.http.put(`${this.baseUrl}/deals/${dealId}`, {
			step: {
				current_step: step
			}
		})
	}

	reportError(responsible, dealId){
		return this.http.post(`${this.proxyUrl}/v1/livechat-report`,
			{responsible, dealId}
		)
	}
}
